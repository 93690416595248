import React from 'react'

export default () => {

    return <svg version="1.1" x="0px" y="0px" viewBox="0 0 768.1 149.61">
    
    <g>
        <path className="text" d="M300.05,68.8V32.16h23.34v5.89h-16.34v8.73h12.23v5.89h-12.23V68.8H300.05z"/>
        <path className="text" d="M334.71,41.9c7.16,0,11.47,5.33,11.47,13.7s-4.31,13.7-11.47,13.7c-7.16,0-11.47-5.33-11.47-13.7
            S327.56,41.9,334.71,41.9z M334.71,46.97c-3.5,0-5.18,2.74-5.18,8.63s1.67,8.63,5.18,8.63c3.5,0,5.18-2.74,5.18-8.63
            S338.22,46.97,334.71,46.97z"/>
        <path className="text" d="M350.24,68.8V42.41h5.78v4.52h0.1c1.52-3.14,3.65-4.92,7.41-5.02v6.65c-0.3-0.05-0.66-0.05-1.12-0.05
            c-3.15,0-5.89,3.6-5.89,4.26V68.8H350.24z"/>
        <path className="text" d="M389.02,42.41V68.8h-5.78v-2.84c-2.33,1.62-5.03,3.35-8.12,3.35c-5.28,0-7.82-3.45-7.82-8.83V42.41h6.29v17.16
            c0,3.55,1.12,4.67,3.25,4.67c2.74,0,4.82-1.58,5.89-2.79V42.41H389.02z"/>
        <path className="text" d="M394.86,68.8V42.41h5.74v2.84c2.38-2.18,4.31-3.35,7.92-3.35c2.74,0,5.33,1.32,6.5,3.86
            c2.18-2.18,4.72-3.86,9.08-3.86c2.74,0,6.5,1.62,6.5,7.92V68.8h-6.29V50.38c0-2.13-0.86-3.3-3.15-3.4c-1.62,0-3.8,1.47-5.28,2.84
            V68.8h-6.29V50.38c0-2.13-0.86-3.3-3.15-3.4c-1.62,0-3.81,1.47-5.28,2.84V68.8H394.86z"/>
        <path className="text" d="M458.4,47.48V68.8h-6.29V47.48h-3.65v-5.08h3.6v-3.25c0-5.68,2.28-7.51,7.46-7.51h4.16v5.07h-1.73
            c-2.64,0-3.55,0.81-3.55,2.84v2.84h5.33v5.08H458.4z"/>
        <path className="text" d="M477.04,41.9c7.15,0,11.47,5.33,11.47,13.7s-4.31,13.7-11.47,13.7c-7.16,0-11.47-5.33-11.47-13.7
            S469.88,41.9,477.04,41.9z M475.11,32.67v5.88h-6.09v-5.88H475.11z M477.04,46.97c-3.5,0-5.18,2.74-5.18,8.63s1.67,8.63,5.18,8.63
            c3.5,0,5.18-2.74,5.18-8.63S480.54,46.97,477.04,46.97z M484.75,32.67v5.88h-6.09v-5.88H484.75z"/>
        <path className="text" d="M492.57,68.8V42.41h5.78v4.52h0.1c1.52-3.14,3.65-4.92,7.41-5.02v6.65c-0.3-0.05-0.66-0.05-1.12-0.05
            c-3.15,0-5.89,3.6-5.89,4.26V68.8H492.57z"/>
        <path className="text" d="M523.83,68.8V42.41h5.74v2.84c2.38-2.18,4.31-3.35,7.92-3.35c2.74,0,5.33,1.32,6.5,3.86
            c2.18-2.18,4.72-3.86,9.08-3.86c2.74,0,6.5,1.62,6.5,7.92V68.8h-6.29V50.38c0-2.13-0.86-3.3-3.15-3.4c-1.62,0-3.8,1.47-5.28,2.84
            V68.8h-6.29V50.38c0-2.13-0.86-3.3-3.15-3.4c-1.62,0-3.81,1.47-5.28,2.84V68.8H523.83z"/>
        <path className="text" d="M565.25,37.54v-5.89h6.29v5.89H565.25z M571.54,68.8h-6.29V42.41h6.29V68.8z"/>
        <path className="text" d="M577.96,68.8V32.16h6.29V68.8H577.96z"/>
        <path className="text" d="M586.49,72.91c0.66,0.05,1.17,0.15,2.08,0.15c1.47,0,2.33-0.71,2.33-3.25V42.41h6.29v28.42
            c0,5.38-2.23,7.61-7.41,7.61c-1.27,0-2.33-0.15-3.3-0.4V72.91z M590.9,37.54v-5.89h6.29v5.89H590.9z"/>
        <path className="text" d="M612.88,41.9c7.15,0,11.47,5.33,11.47,13.7s-4.31,13.7-11.47,13.7c-7.16,0-11.47-5.33-11.47-13.7
            S605.72,41.9,612.88,41.9z M610.95,32.67v5.88h-6.09v-5.88H610.95z M612.88,46.97c-3.5,0-5.18,2.74-5.18,8.63s1.67,8.63,5.18,8.63
            c3.5,0,5.18-2.74,5.18-8.63S616.38,46.97,612.88,46.97z M620.59,32.67v5.88h-6.09v-5.88H620.59z"/>
        <path className="text" d="M641.3,49.36c-1.27-1.37-2.94-2.79-4.92-2.79c-2.44,0-3.55,1.01-3.55,2.59c0,4.52,13.19,2.59,13.19,12.23
            c0,5.48-4.16,7.92-9.69,7.92c-4.21,0-7.66-1.88-10-5.33l4.21-3.15c1.47,2.03,3.45,3.81,6.14,3.81c2.23,0,3.76-1.22,3.76-2.99
            c0-4.52-13.19-2.89-13.19-12.08c0-4.92,4.16-7.66,8.73-7.66c3.5,0,7,1.32,9.29,4.01L641.3,49.36z"/>
        <path className="text" d="M650.35,68.8V42.41h5.74v2.84c2.39-2.18,4.31-3.35,7.92-3.35c2.74,0,5.33,1.32,6.5,3.86
            c2.18-2.18,4.72-3.86,9.08-3.86c2.74,0,6.5,1.62,6.5,7.92V68.8h-6.29V50.38c0-2.13-0.86-3.3-3.15-3.4c-1.62,0-3.8,1.47-5.28,2.84
            V68.8h-6.29V50.38c0-2.13-0.86-3.3-3.15-3.4c-1.62,0-3.81,1.47-5.28,2.84V68.8H650.35z"/>
        <path className="text" d="M705.7,68.8l-0.41-2.29c-2.54,1.73-4.92,2.79-8.22,2.79c-4.77,0-7.16-3.4-7.16-7c0-5.99,4.87-9.14,15.12-10.35
            v-2.23c0-2.03-1.62-3.14-3.86-3.14c-2.94,0-4.57,1.57-5.79,3.25l-4.06-3.1c1.62-2.79,5.99-4.92,9.85-4.82
            c7.1,0.15,10.15,2.74,10.15,10.66v12.44c0,1.32,0.1,2.64,0.51,3.81H705.7z M705.04,56.16c-4.21,0.81-8.83,1.68-8.83,5.38
            c0,1.98,1.27,3.1,3.05,3.1c1.98,0,4.47-1.67,5.78-2.79V56.16z"/>
        <path className="text" d="M716.77,68.8V42.41h5.78v4.52h0.1c1.52-3.14,3.65-4.92,7.41-5.02v6.65c-0.3-0.05-0.66-0.05-1.12-0.05
            c-3.15,0-5.89,3.6-5.89,4.26V68.8H716.77z"/>
        <path className="text" d="M735.89,42.41v-8.73h6.29v8.73h5.18v5.08h-5.18V62.4c0,1.42,0.71,1.83,2.28,1.83c0.96,0,1.98-0.31,2.89-0.51
            v5.18c-1.73,0.2-3.25,0.4-4.97,0.4c-4.67,0-6.5-2.33-6.5-6.09V47.48h-3.65v-5.08H735.89z"/>
    </g>
    <g>
        <path className="text" d="M300.12,118.64V82h6.29v20.45h0.2l7.77-10.2h7.21l-7.76,9.84l8.32,16.54h-6.7l-5.84-11.82l-3.2,3.7v8.12
            H300.12z"/>
        <path className="text" d="M334.09,91.75c7.16,0,11.47,5.33,11.47,13.7s-4.31,13.7-11.47,13.7c-7.16,0-11.47-5.33-11.47-13.7
            S326.93,91.75,334.09,91.75z M334.09,96.82c-3.5,0-5.18,2.74-5.18,8.63c0,5.89,1.67,8.63,5.18,8.63s5.18-2.74,5.18-8.63
            C339.27,99.56,337.59,96.82,334.09,96.82z"/>
        <path className="text" d="M349.82,118.64V92.26h5.79v2.84c2.33-1.63,5.02-3.35,8.12-3.35c5.28,0,7.82,3.45,7.82,8.83v18.07h-6.29v-17.15
            c0-3.55-1.12-4.67-3.25-4.67c-2.74,0-4.82,1.57-5.89,2.79v19.03H349.82z"/>
        <path className="text" d="M390.17,99.21c-1.27-1.37-2.94-2.79-4.92-2.79c-2.44,0-3.55,1.02-3.55,2.59c0,4.52,13.19,2.59,13.19,12.23
            c0,5.48-4.16,7.92-9.69,7.92c-4.21,0-7.66-1.88-10-5.33l4.21-3.15c1.47,2.03,3.45,3.81,6.14,3.81c2.23,0,3.76-1.22,3.76-3
            c0-4.52-13.19-2.89-13.19-12.08c0-4.92,4.16-7.66,8.73-7.66c3.5,0,7,1.32,9.29,4.01L390.17,99.21z"/>
        <path className="text" d="M420.74,92.26v26.39h-5.78v-2.84c-2.33,1.62-5.03,3.35-8.12,3.35c-5.28,0-7.82-3.45-7.82-8.83V92.26h6.29
            v17.15c0,3.55,1.12,4.67,3.25,4.67c2.74,0,4.82-1.57,5.89-2.79V92.26H420.74z"/>
        <path className="text" d="M426.59,118.64V92.26h5.74v2.84c2.39-2.18,4.31-3.35,7.92-3.35c2.74,0,5.33,1.32,6.5,3.86
            c2.18-2.18,4.72-3.86,9.08-3.86c2.74,0,6.5,1.62,6.5,7.92v18.98h-6.29v-18.42c0-2.13-0.86-3.3-3.15-3.4c-1.62,0-3.8,1.47-5.28,2.84
            v18.98h-6.29v-18.42c0-2.13-0.86-3.3-3.15-3.4c-1.62,0-3.81,1.47-5.28,2.84v18.98H426.59z"/>
        <path className="text" d="M469.4,92.26v-8.73h6.29v8.73h5.18v5.07h-5.18v14.92c0,1.42,0.71,1.83,2.28,1.83c0.96,0,1.98-0.3,2.89-0.51
            v5.18c-1.73,0.2-3.25,0.41-4.97,0.41c-4.67,0-6.5-2.34-6.5-6.09V97.33h-3.65v-5.07H469.4z"/>
        <path className="text" d="M485.3,87.38V81.5h6.29v5.89H485.3z M491.59,118.64h-6.29V92.26h6.29V118.64z"/>
        <path className="text" d="M507.65,91.75c7.15,0,11.47,5.33,11.47,13.7s-4.31,13.7-11.47,13.7c-7.16,0-11.47-5.33-11.47-13.7
            S500.49,91.75,507.65,91.75z M507.65,96.82c-3.5,0-5.18,2.74-5.18,8.63c0,5.89,1.67,8.63,5.18,8.63c3.5,0,5.18-2.74,5.18-8.63
            C512.82,99.56,511.15,96.82,507.65,96.82z"/>
        <path className="text" d="M523.38,118.64V92.26h5.79v2.84c2.33-1.63,5.02-3.35,8.12-3.35c5.28,0,7.82,3.45,7.82,8.83v18.07h-6.29v-17.15
            c0-3.55-1.12-4.67-3.25-4.67c-2.74,0-4.82,1.57-5.89,2.79v19.03H523.38z"/>
    </g>
    <line className="line" x1="275.72" y1="23.87" x2="275.72" y2="125.06"/>
    <g>
        <path className="box" d="M23.88,125.01c-5.92,0-10.75-4.8-10.75-10.71V34.54c0-5.91,4.82-10.71,10.75-10.71h216.47
            c5.92,0,10.74,4.81,10.74,10.71v79.76c0,5.91-4.82,10.71-10.74,10.71H23.88z"/>
        <g>
            <g>
                <path className="boxtext" d="M38.13,69.42h-3.17V40.78h3.17v14.88h0.08l11.86-14.88h3.77l-8.89,10.79l9.96,17.85H51.3l-8.53-15.24
                    l-4.64,5.63V69.42z"/>
                <path className="boxtext" d="M55.78,69.93H51l-8.31-14.85L38.64,60v9.93h-4.19V40.27h4.19V54.3l11.19-14.03h5.1l-9.35,11.36L55.78,69.93z
                     M51.6,68.91h2.44l-9.7-17.4l8.42-10.23h-2.44L38.46,56.16H37.7l-0.08-0.51V41.29h-2.15v27.63h2.15v-9.27l5.23-6.35L51.6,68.91z"
                    />
            </g>
            <g>
                <path className="boxtext" d="M67.13,69.82c-5.63,0-8.45-4.76-8.45-10.71c0-5.95,2.82-10.71,8.45-10.71c5.63,0,8.45,4.76,8.45,10.71
                    C75.59,65.06,72.77,69.82,67.13,69.82z M67.13,50.85c-3.41,0-5.59,2.94-5.59,8.25s2.18,8.25,5.59,8.25
                    c3.41,0,5.59-2.94,5.59-8.25S70.55,50.85,67.13,50.85z"/>
                <path className="boxtext" d="M67.13,70.33c-5.44,0-8.96-4.41-8.96-11.22c0-6.82,3.52-11.22,8.96-11.22c5.44,0,8.96,4.41,8.96,11.22
                    C76.1,65.92,72.58,70.33,67.13,70.33z M67.13,48.9c-5.85,0-7.94,5.27-7.94,10.2c0,4.93,2.09,10.2,7.94,10.2
                    c5.85,0,7.94-5.27,7.94-10.2C75.08,54.17,72.99,48.9,67.13,48.9z M67.13,67.87c-3.82,0-6.1-3.28-6.1-8.76
                    c0-5.49,2.28-8.76,6.1-8.76c3.82,0,6.1,3.28,6.1,8.76C73.24,64.59,70.96,67.87,67.13,67.87z M67.13,51.36
                    c-3.18,0-5.08,2.89-5.08,7.74c0,4.85,1.9,7.74,5.08,7.74c3.18,0,5.08-2.89,5.08-7.74C72.22,54.26,70.32,51.36,67.13,51.36z"/>
            </g>
            <g>
                <path className="boxtext" d="M83.84,48.79v2.78h0.08c1.63-1.83,3.77-3.17,6.11-3.17c3.05,0,5.95,1.59,5.95,6.03v15h-2.86V54.5
                    c0-2.74-1.31-3.65-3.37-3.65c-2.14,0-4.6,1.75-5.91,3.13v15.43h-2.86V48.79H83.84z"/>
                <path className="boxtext" d="M96.49,69.93h-3.88V54.5c0-2.2-0.86-3.14-2.86-3.14c-1.82,0-4.04,1.44-5.4,2.83v15.74h-3.88V48.28h3.88v2.12
                    c1.74-1.63,3.73-2.51,5.68-2.51c4.05,0,6.46,2.45,6.46,6.54V69.93z M93.63,68.91h1.84V54.42c0-4.98-3.81-5.52-5.44-5.52
                    c-1.97,0-4,1.07-5.73,3l-0.15,0.17h-0.74l-0.08-0.51V49.3h-1.84v19.61h1.84V53.78l0.14-0.15c1.15-1.22,3.78-3.29,6.28-3.29
                    c2.58,0,3.88,1.4,3.88,4.16V68.91z"/>
            </g>
            <g>
                <path className="boxtext" d="M103.12,64.18c1.35,1.94,3.33,3.17,5.91,3.17c3.09,0,4.44-1.67,4.44-3.41c0-5.24-12.22-2.46-11.98-10
                    c0.12-3.57,2.86-5.59,6.82-5.55c2.7,0.04,5.67,1.51,7.06,3.61l-1.98,1.75c-1.59-1.94-3.09-2.9-5.32-2.9
                    c-2.42,0-3.73,1.23-3.73,3.05c0,4.48,11.98,2.18,11.98,9.88c0,4.01-3.49,6.03-7.22,6.03c-3.21,0-6.35-1.39-8.25-3.93
                    L103.12,64.18z"/>
                <path className="boxtext" d="M109.11,70.33c-3.48,0-6.72-1.54-8.66-4.13l-0.3-0.41l3.09-2.33l0.3,0.44c1.34,1.93,3.24,2.96,5.49,2.96
                    c2.9,0,3.93-1.5,3.93-2.9c0-1.86-1.84-2.52-4.87-3.4c-3.23-0.93-7.26-2.1-7.11-6.61c0.12-3.73,2.89-6.05,7.22-6.05l0.12,0
                    c2.94,0.04,6.02,1.62,7.48,3.84l0.24,0.37l-2.71,2.39L113,54.07c-1.59-1.95-2.97-2.71-4.92-2.71c-2.05,0-3.22,0.93-3.22,2.54
                    c0,1.46,1.67,2.06,4.43,2.88c3.37,1,7.55,2.25,7.55,7C116.84,68.28,112.84,70.33,109.11,70.33z M101.59,65.98
                    c1.77,2.09,4.55,3.33,7.53,3.33c3.34,0,6.71-1.71,6.71-5.52c0-3.99-3.47-5.02-6.83-6.02c-2.65-0.79-5.16-1.54-5.16-3.86
                    c0-2.2,1.62-3.57,4.24-3.57c2.12,0,3.71,0.78,5.37,2.68l1.25-1.1c-1.36-1.74-3.97-2.99-6.39-3.02l-0.11,0
                    c-3.78,0-6.1,1.89-6.2,5.06c-0.12,3.72,3.18,4.68,6.38,5.6c2.76,0.8,5.61,1.62,5.61,4.38c0,1.95-1.53,3.92-4.95,3.92
                    c-2.43,0-4.5-1.02-6.02-2.97L101.59,65.98z"/>
            </g>
            <g>
                <path className="boxtext" d="M133.59,69.42v-2.78h-0.08c-1.63,1.82-3.77,3.17-6.11,3.17c-3.05,0-5.95-1.59-5.95-6.03v-15h2.86v14.92
                    c0,2.74,1.31,3.65,3.37,3.65c2.14,0,4.6-1.75,5.91-3.13V48.79h2.86v20.63H133.59z"/>
                <path className="boxtext" d="M127.4,70.33c-4.05,0-6.46-2.45-6.46-6.54V48.28h3.88v15.43c0,2.2,0.86,3.14,2.86,3.14
                    c1.82,0,4.04-1.44,5.4-2.83V48.28h3.88v21.65h-3.88v-2.12C131.34,69.44,129.36,70.33,127.4,70.33z M121.96,49.3v14.49
                    c0,4.98,3.81,5.52,5.44,5.52c1.97,0,4-1.07,5.73-3l0.15-0.17h0.74l0.08,0.51v2.27h1.84V49.3h-1.84v15.13l-0.14,0.15
                    c-1.15,1.22-3.78,3.29-6.28,3.29c-2.58,0-3.88-1.4-3.88-4.16V49.3H121.96z"/>
            </g>
            <g>
                <path className="boxtext" d="M143.63,69.42V48.79h2.86v2.74l0.16-0.16c1.74-1.94,4.01-2.98,5.99-2.98c2.66,0,4.52,1.35,5.28,3.25
                    c1.83-1.94,4.13-3.25,6.55-3.25c2.74,0,5.67,1.15,5.67,6.27v14.76h-2.86V54.66c0-2.78-1.39-3.81-3.21-3.81
                    c-2.54,0-4.21,1.67-5.75,3.17v15.39h-2.86V54.66c0-2.78-1.39-3.81-3.21-3.81c-2.54,0-4.21,1.67-5.75,3.17v15.39H143.63z"/>
                <path className="boxtext" d="M170.65,69.93h-3.88V54.66c0-2.19-0.91-3.3-2.7-3.3c-2.25,0-3.78,1.45-5.24,2.88v15.69h-3.88V54.66
                    c0-2.19-0.91-3.3-2.7-3.3c-2.25,0-3.78,1.45-5.24,2.88v15.69h-3.88V48.28H147v2.01c1.65-1.52,3.71-2.41,5.64-2.41
                    c2.43,0,4.4,1.07,5.42,2.89c1.95-1.87,4.2-2.89,6.4-2.89c2.82,0,6.18,1.18,6.18,6.78V69.93z M167.79,68.91h1.84V54.66
                    c0-3.88-1.69-5.76-5.16-5.76c-2.11,0-4.3,1.1-6.18,3.09l-0.55,0.59l-0.3-0.75c-0.73-1.84-2.52-2.93-4.8-2.93
                    c-1.94,0-4.03,1.05-5.61,2.81l-1.05,1.05V49.3h-1.84v19.61h1.84v-15.1l0.23-0.23c1.56-1.52,3.33-3.24,6.03-3.24
                    c2.37,0,3.72,1.57,3.72,4.32v14.25h1.84v-15.1l0.23-0.22c1.56-1.52,3.33-3.25,6.04-3.25c2.37,0,3.72,1.57,3.72,4.32V68.91z"/>
            </g>
            <g>
                <path className="boxtext" d="M192.32,65.02c-1.35,2.34-3.25,4.8-7.94,4.8c-4.6,0-8.97-3.17-8.97-10.71c0-6.67,3.33-10.71,8.41-10.71
                    c4.29,0,7.86,2.78,7.86,9.36v1.9h-13.41c0,4.52,2.7,7.7,6.11,7.7c2.54,0,4.52-1.35,5.71-3.49L192.32,65.02z M188.82,57.2
                    c0-3.81-1.82-6.35-4.92-6.35c-3.45,0-5.32,2.54-5.55,6.35H188.82z"/>
                <path className="boxtext" d="M184.38,70.33c-4.37,0-9.48-2.94-9.48-11.22c0-6.82,3.5-11.22,8.92-11.22c5.24,0,8.37,3.69,8.37,9.87v2.41
                    h-13.4c0.19,3.84,2.53,6.68,5.59,6.68c2.24,0,4.11-1.15,5.27-3.23l0.24-0.43l3.14,1.62l-0.27,0.46
                    C191.24,67.9,189.18,70.33,184.38,70.33z M183.82,48.9c-4.87,0-7.9,3.91-7.9,10.2c0,7.52,4.37,10.2,8.46,10.2
                    c4.18,0,5.97-2.03,7.22-4.09l-1.32-0.68c-1.36,2.15-3.44,3.33-5.91,3.33c-3.77,0-6.62-3.53-6.62-8.21v-0.51h13.41v-1.39
                    C191.17,52.13,188.49,48.9,183.82,48.9z M189.33,57.71h-11.53l0.03-0.54c0.27-4.27,2.53-6.83,6.06-6.83
                    c3.3,0,5.43,2.69,5.43,6.86V57.71z M178.91,56.69h9.4c-0.15-3.3-1.81-5.33-4.4-5.33C180.15,51.36,179.15,54.49,178.91,56.69z"/>
            </g>
            <g>
                <path className="boxtext" d="M200.45,48.79v2.78h0.08c1.63-1.83,3.77-3.17,6.11-3.17c3.05,0,5.95,1.59,5.95,6.03v15h-2.86V54.5
                    c0-2.74-1.31-3.65-3.37-3.65c-2.14,0-4.6,1.75-5.91,3.13v15.43h-2.86V48.79H200.45z"/>
                <path className="boxtext" d="M213.1,69.93h-3.88V54.5c0-2.2-0.86-3.14-2.86-3.14c-1.82,0-4.04,1.44-5.4,2.83v15.74h-3.88V48.28h3.88v2.12
                    c1.74-1.63,3.73-2.51,5.68-2.51c4.05,0,6.46,2.45,6.46,6.54V69.93z M210.24,68.91h1.84V54.42c0-4.98-3.81-5.52-5.44-5.52
                    c-1.97,0-4,1.07-5.73,3l-0.15,0.17h-0.74l-0.08-0.51V49.3h-1.84v19.61h1.84V53.78l0.14-0.15c1.15-1.22,3.78-3.29,6.28-3.29
                    c2.58,0,3.88,1.4,3.88,4.16V68.91z"/>
            </g>
            <g>
                <path className="boxtext" d="M220.61,48.79v-6.78h2.86v6.78h4.76v2.46h-4.76v14.68c0,0.95,0.67,1.43,2.34,1.43c0.95,0,1.79-0.08,2.54-0.2
                    v2.34c-0.52,0.16-1.67,0.32-3.33,0.32c-3.09,0-4.4-0.79-4.4-3.41V51.25h-3.37v-2.46H220.61z"/>
                <path className="boxtext" d="M225.01,70.33c-2.95,0-4.91-0.67-4.91-3.92V51.76h-3.37v-3.48h3.37V41.5h3.88v6.78h4.76v3.48h-4.76v14.17
                    c0,0.32,0,0.92,1.83,0.92c0.84,0,1.64-0.06,2.46-0.19l0.59-0.09v3.31l-0.36,0.11C227.8,70.2,226.5,70.33,225.01,70.33z
                     M217.74,50.74h3.37v15.67c0,2.2,0.95,2.9,3.89,2.9c1.3,0,2.26-0.1,2.82-0.21v-1.35c-0.67,0.08-1.34,0.12-2.03,0.12
                    c-1.89,0-2.85-0.65-2.85-1.94V50.74h4.76V49.3h-4.76v-6.78h-1.84v6.78h-3.37V50.74z"/>
            </g>
            <g>
                <path className="boxtext" d="M44.91,103.43h-3.81L34.76,82.8h2.9l5.24,17.06h0.08l5.24-17.06h2.9L44.91,103.43z"/>
                <path className="boxtext" d="M45.29,103.94h-4.57l-6.66-21.65h3.96l4.9,15.96l4.9-15.96h3.96L45.29,103.94z M41.48,102.92h3.05
                    l5.88-19.61h-1.83l-5.24,17.06h-0.75l-0.19-0.36l-5.13-16.7h-1.83L41.48,102.92z"/>
            </g>
            <g>
                <path className="boxtext" d="M70.63,99.03c-1.35,2.34-3.25,4.8-7.94,4.8c-4.6,0-8.97-3.17-8.97-10.71c0-6.67,3.33-10.71,8.41-10.71
                    c4.29,0,7.86,2.78,7.86,9.36v1.9H56.58c0,4.52,2.7,7.7,6.11,7.7c2.54,0,4.52-1.35,5.71-3.49L70.63,99.03z M67.13,91.21
                    c0-3.81-1.82-6.35-4.92-6.35c-3.45,0-5.32,2.54-5.55,6.35H67.13z"/>
                <path className="boxtext" d="M62.69,104.34c-4.37,0-9.48-2.94-9.48-11.22c0-6.82,3.5-11.22,8.92-11.22c5.24,0,8.37,3.69,8.37,9.87v2.42
                    H57.1c0.19,3.84,2.53,6.68,5.59,6.68c2.24,0,4.11-1.15,5.27-3.23l0.24-0.43l3.14,1.62l-0.27,0.46
                    C69.55,101.91,67.49,104.34,62.69,104.34z M62.13,82.91c-4.87,0-7.9,3.91-7.9,10.2c0,7.52,4.37,10.2,8.46,10.2
                    c4.18,0,5.97-2.03,7.22-4.09l-1.32-0.68c-1.36,2.15-3.44,3.33-5.91,3.33c-3.77,0-6.62-3.53-6.62-8.21v-0.51h13.41v-1.39
                    C69.48,86.14,66.8,82.91,62.13,82.91z M67.64,91.72H56.12l0.03-0.54c0.27-4.27,2.53-6.83,6.06-6.83c3.3,0,5.43,2.69,5.43,6.86
                    V91.72z M57.22,90.7h9.4c-0.15-3.3-1.81-5.33-4.4-5.33C58.46,85.37,57.46,88.49,57.22,90.7z"/>
            </g>
            <g>
                <path className="boxtext" d="M75.15,82.8h2.86v4.09h0.08c1.59-2.82,3.29-4.4,6.71-4.4v2.86c-3.57,0.08-5.44,2.42-6.78,6.67v11.43h-2.86
                    V82.8z"/>
                <path className="boxtext" d="M78.52,103.94h-3.88V82.29h3.88v2.95c1.41-2,3.19-3.26,6.27-3.26h0.51v3.87l-0.5,0.01
                    c-3.06,0.07-4.89,1.88-6.29,6.23V103.94z M75.66,102.92h1.84l0.02-11.07c1.47-4.61,3.5-6.74,6.76-7v-1.85
                    c-2.92,0.16-4.39,1.71-5.75,4.13l-0.15,0.26h-0.81l-0.08-0.51v-3.58h-1.84V102.92z"/>
            </g>
            <g>
                <path className="boxtext" d="M89.31,74.78h2.86v18.45h0.08l8.33-10.43h3.45l-6.07,7.62l6.86,13.01h-3.37l-5.48-10.55l-3.81,4.37v6.19
                    h-2.86V74.78z"/>
                <path className="boxtext" d="M105.67,103.94h-4.53l-5.27-10.16l-3.19,3.66v6.51H88.8V74.27h3.88v17.6l7.66-9.59h4.76l-6.52,8.19
                    L105.67,103.94z M101.76,102.92h2.22l-6.62-12.56l5.62-7.05h-2.15l-8.33,10.43h-0.76l-0.08-0.51V75.29h-1.84v27.63h1.84v-5.87
                    l4.43-5.07L101.76,102.92z"/>
            </g>
            <g>
                <path className="boxtext" d="M124.71,99.03c-1.35,2.34-3.25,4.8-7.94,4.8c-4.6,0-8.97-3.17-8.97-10.71c0-6.67,3.33-10.71,8.41-10.71
                    c4.29,0,7.86,2.78,7.86,9.36v1.9h-13.41c0,4.52,2.7,7.7,6.11,7.7c2.54,0,4.52-1.35,5.71-3.49L124.71,99.03z M121.21,91.21
                    c0-3.81-1.82-6.35-4.92-6.35c-3.45,0-5.32,2.54-5.55,6.35H121.21z"/>
                <path className="boxtext" d="M116.77,104.34c-4.37,0-9.48-2.94-9.48-11.22c0-6.82,3.5-11.22,8.92-11.22c5.24,0,8.37,3.69,8.37,9.87v2.42
                    h-13.4c0.19,3.84,2.53,6.68,5.59,6.68c2.24,0,4.11-1.15,5.27-3.23l0.24-0.43l3.14,1.62l-0.27,0.46
                    C123.63,101.91,121.57,104.34,116.77,104.34z M116.21,82.91c-4.87,0-7.9,3.91-7.9,10.2c0,7.52,4.37,10.2,8.46,10.2
                    c4.18,0,5.97-2.03,7.22-4.09l-1.32-0.68c-1.36,2.15-3.44,3.33-5.91,3.33c-3.77,0-6.62-3.53-6.62-8.21v-0.51h13.41v-1.39
                    C123.56,86.14,120.88,82.91,116.21,82.91z M121.72,91.72H110.2l0.03-0.54c0.27-4.27,2.53-6.83,6.06-6.83
                    c3.3,0,5.43,2.69,5.43,6.86V91.72z M111.29,90.7h9.4c-0.15-3.3-1.81-5.33-4.4-5.33C112.54,85.37,111.54,88.49,111.29,90.7z"/>
            </g>
            <g>
                <path className="boxtext" d="M130.54,82.8v-6.78h2.86v6.78h4.76v2.46h-4.76v14.68c0,0.95,0.67,1.43,2.34,1.43c0.95,0,1.79-0.08,2.54-0.2
                    v2.34c-0.52,0.16-1.67,0.32-3.33,0.32c-3.09,0-4.4-0.79-4.4-3.41V85.26h-3.37V82.8H130.54z"/>
                <path className="boxtext" d="M134.94,104.34c-2.95,0-4.91-0.67-4.91-3.92V85.77h-3.37v-3.48h3.37V75.5h3.88v6.78h4.76v3.48h-4.76v14.17
                    c0,0.32,0,0.92,1.83,0.92c0.84,0,1.64-0.06,2.46-0.19l0.59-0.09v3.32l-0.36,0.11C137.73,104.21,136.43,104.34,134.94,104.34z
                     M127.68,84.75h3.37v15.67c0,2.2,0.95,2.9,3.89,2.9c1.3,0,2.26-0.1,2.82-0.21v-1.35c-0.67,0.08-1.34,0.12-2.03,0.12
                    c-1.89,0-2.85-0.65-2.85-1.94V84.75h4.76v-1.44h-4.76v-6.78h-1.84v6.78h-3.37V84.75z"/>
            </g>
            <g>
                <path className="boxtext" d="M185.33,103.43h-3.17V76.1h3.17V90.3h0.08l11.86-14.19h3.77l-8.89,10.29l9.96,17.03h-3.61l-8.53-14.53
                    l-4.64,5.37V103.43z"/>
                <path className="boxtext" d="M203,103.94h-4.79l-8.32-14.17l-4.05,4.69v9.48h-4.2V75.59h4.2v13.39l11.19-13.39h5.12l-9.38,10.86
                    L203,103.94z M198.8,102.92h2.43l-9.69-16.58l8.4-9.73h-2.42l-11.86,14.19h-0.75l-0.08-0.51V76.61h-2.15v26.31h2.15v-8.84
                    l5.23-6.06L198.8,102.92z"/>
            </g>
            <g>
                <path className="boxtext" d="M217.63,75.72c7.02,0,11.19,4.92,11.19,14.04c0,9.12-4.17,14.04-11.19,14.04c-7.02,0-11.19-4.92-11.19-14.04
                    C206.44,80.65,210.61,75.72,217.63,75.72z M217.63,101.23c5.04,0,8.01-3.71,8.01-11.47c0-7.76-2.98-11.47-8.01-11.47
                    c-5.04,0-8.01,3.71-8.01,11.47C209.61,97.53,212.59,101.23,217.63,101.23z"/>
                <path className="boxtext" d="M217.63,104.32c-7.43,0-11.7-5.3-11.7-14.55s4.26-14.55,11.7-14.55c7.43,0,11.7,5.3,11.7,14.55
                    S225.06,104.32,217.63,104.32z M217.63,76.24c-6.89,0-10.68,4.81-10.68,13.53s3.79,13.53,10.68,13.53
                    c6.89,0,10.68-4.81,10.68-13.53S224.51,76.24,217.63,76.24z M217.63,101.75c-5.5,0-8.53-4.25-8.53-11.98
                    c0-7.72,3.03-11.98,8.53-11.98c5.5,0,8.53,4.25,8.53,11.98C226.15,97.49,223.13,101.75,217.63,101.75z M217.63,78.81
                    c-6.2,0-7.5,5.96-7.5,10.96c0,5,1.3,10.96,7.5,10.96c6.2,0,7.5-5.96,7.5-10.96C225.13,84.77,223.83,78.81,217.63,78.81z"/>
            </g>
            <path className="boxtext" d="M159.7,87.29c2.07,0,3.75,1.66,3.75,3.73c0,2.07-1.68,3.75-3.75,3.75c-2.07,0-3.73-1.68-3.73-3.75
                C155.97,88.95,157.63,87.29,159.7,87.29z"/>
        </g>
    </g>
    </svg>
    


}