import { css } from "@emotion/core"
import { large, medium } from "./Variables"

export const mainWrapperStyle = css`
    background-color:#EDEAEA;
`

export const innerContentStyle = css`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`

export const headerStyle = css`
    height: 131px;
    padding: 30px 16px 8px 16px;
    box-sizing: border-box;
    background-color: #fff;

    justify-content: center;
    display: flex;

    ${medium}{
        padding: 30px 0px 8px 0px;
    }

    svg{
        max-height: 100%;
        width: 100%;
        .text{fill:#162259;}
        .box {fill:#162259}
        .box{stroke:#162259;stroke-miterlimit:10;}
        .line{stroke: #000; stroke-miterlimit:10;}
        .boxtext{fill:#fff;}

    }
`
    

export const mainStyle = css`
    
    padding: 64px 16px 114px 16px;
    background-color: #F5F5F0;
    background-image: url('/wave.png');
    background-size: 100% 100%;
    background-position-y: 90px;
    background-repeat: no-repeat;

    ${large}{ 
        padding: 64px 0 114px 0;
    }

`

export const byeboxStyle = css`
    margin: 0 auto;
    max-width: 114rem;
    box-shadow: 0 4px 8px 2px rgba(0,0,0,0.25);
    border-radius: 12px;
    padding: 6.4rem 1.6rem;
    box-sizing: border-box;
    text-align: center;
    z-index: 2;  
    position: relative;
    background-color: #fff;
    font-weight: 400;
    color: #000;

    h1{
        margin-bottom: 3.2rem;
    }

    &:after,&:before{
        content: "";
        opacity: 0.25;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;   
    }
    &:after{
        background-image: url('/FFMK_blomma.svg');
        background-repeat: no-repeat;
        background-position: 100% 130%;
    }
    &:before{
        background-image: url('/FFMK_blomma.svg');
        background-repeat: no-repeat;
        background-position: 117% 70%;
        transform: rotate(180deg);
    }


    ${medium}{
        &:before,&:after{
            display: none;
        }
        background-image: url('/FFMK_blomma.svg');
        background-repeat: no-repeat;
        background-position: 100% 100%;
        padding: 6.4rem 0;
        h1{
            padding: 0 10% 0 10%;
        }

        p{
            padding: 0 10%;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 64px;
        }
    }
    ${large}{
        background-image: url('/FFMK_blomma.svg'), url('/FFMK_vatten.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: 100% 100%, 31px 22px;
        h1{
            padding: 0 211px 0 204px;
        }

        p{
            padding: 0 195px;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 64px;
        }
    }

`

export const buttonStyle = css`
    font-size: 20px;
    padding-top: 16px !important;
    padding-bottom: 18px !important;
`

export const footerStyle = css`
    background-color: #162259;
    margin-top: auto;
    min-width: 100%;
    padding: 50px 16px;
    box-sizing: border-box;
    ${large}{
        padding: 50px 0px;
    }
`


export const footerInnerStyle = css`
    margin: 0 auto;
    color: #fff;
    max-width: 114rem;
    text-align:center;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 20px;
`

export const footerLogoStyle = css`
    box-sizing: border-box;
    height: 90px;
    padding-bottom: 17px;
    border-bottom: 3px solid #444E7A;
    margin-bottom: 31px;

    svg{
        max-height: 100%;

        .text{fill:#fff;}
        .box {fill:#272B57}
        .box{stroke:#fff;stroke-miterlimit:10;}
        .line{stroke: #fff; stroke-miterlimit:10;}
        .boxtext{fill:#fff;}
    }

     

`