/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import {GlobalStyles} from '@bit/konsumentverket.kov-compo.global-styles';
import {SubHeading} from '@bit/konsumentverket.kov-compo.sub-heading';
import {Button} from '@bit/konsumentverket.kov-compo.button';
import External from '@bit/konsumentverket.kov-compo.external';

import {mainWrapperStyle,innerContentStyle, headerStyle, mainStyle, byeboxStyle, footerStyle, footerInnerStyle, footerLogoStyle, buttonStyle} from './App.css.js';
import Logo from './Logo';


function App() {
  return (
    <>
      <GlobalStyles />
      <div css={mainWrapperStyle}>
        <div css={innerContentStyle}>
          <header css={headerStyle}><Logo /></header>
          <main css={mainStyle}>
            <div css={byeboxStyle}>
              <SubHeading level={1} styleLevel={1} text="Denna webbplats stängdes ner den 15 december 2020"/>
              <p>
                Konsumentverket hade år 2017-2020 ett uppdrag att tillhandahålla ett forum för samhällsaktörer 
                som arbetar med miljömässigt hållbar konsumtion. Verksamheten med forumet bedrivs inte längre. 
                Är du intresserad av Konsumentverkets arbete, besök istället Konsumentverkets webbplats.
                Har du frågor om ditt konto, kontakta <a href="mailto:webbredaktionen@konsumentverket.se">webbredaktionen@konsumentverket.se</a>
              </p>
              <Button style={buttonStyle} href="https://www.konsumentverket.se/" text="Konsumentverkets webbplats" iconRight={<External />}/>
            </div>
          </main>
          <footer css={footerStyle}>
            <div css={footerInnerStyle}>
              <div css={footerLogoStyle}>
                <Logo />
              </div>
              Konsumentverket/KO, Box 48, 651 02 Karlstad<br />
              Telefon: 0771-42 33 00<br />
              E-post: konsumentverket@konsumentverket.se
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

export default App;
